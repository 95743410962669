import { motion, useMotionValue, useTransform } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { borderWidth, colors, radius, sizes, spacings } from "../assets/themes";
import { getCssProperty } from "./Styles/Helper";
import { Body14, Body16 } from "./Text";
import { useDebounce } from "@/modules/hooks";

const getRealValue = (number, inRange, outRange) =>
  ((number - inRange[0]) * (outRange[1] - outRange[0])) /
    (inRange[1] - inRange[0]) +
  outRange[0];

const getNearestStep = (v, step) => Math.ceil(v / step) * step;

const KNOB_SIZE = sizes.size20;
const TRACK_GAP = spacings.s;
const TRACK_COLOR = colors.primary;

const Knob = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTrackValue = styled.div`
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  background: ${TRACK_COLOR};
  z-index: 9;
  color: ${colors.white};
  margin-bottom: ${TRACK_GAP};
  padding: ${spacings.sm} ${spacings.m};
  position: absolute;
  bottom: 100%;
  width: fit-content;
  border-radius: ${radius.circle};
  align-items: center;
  justify-content: center;
  user-select: none;
  &:before {
    content: "";
    position: absolute;
    width: ${borderWidth.l};
    height: ${Number(getCssProperty(spacings.m).replace("px", "")) + 2}px;
    background: ${TRACK_COLOR};
    bottom: -${Number(getCssProperty(TRACK_GAP).replace("px", "")) + 2}px;
  }
  span {
    width: 100%;
    white-space: nowrap;
  }
`;

const StyledKnob = styled.div`
  transition: width 0.2s;
  width: ${KNOB_SIZE};
  height: ${KNOB_SIZE};
  border-radius: 50%;
  background: ${colors.white};
  border: solid ${borderWidth.l} ${TRACK_COLOR};
  outline: ${borderWidth.l} solid ${colors.transparent};
  transition: 0.2s;
`;

const AnimatedKnob = Knob.withComponent(motion.div);

const StyledAnimatedKnob = styled(AnimatedKnob)`
  margin-left: calc((${KNOB_SIZE} / 2) * -1);
  &:hover {
    ${StyledTrackValue} {
      display: flex !important;
    }
  }
`;

const AnimatedHolder = Knob.withComponent(motion.div);

const Holder = styled(AnimatedHolder)`
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  position: relative;
  touch-action: none;
  z-index: 1;
  &:focus {
    ${StyledKnob} {
      border-width: calc(${KNOB_SIZE} / 2);
      outline: ${borderWidth.l} solid ${colors.primaryLight};
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed !important;
      pointer-events: none;
      ${StyledAnimatedKnob} {
        cursor: not-allowed !important;
      }
    `}
`;

const Track = styled(motion.div)`
  width: 100%;
  height: ${borderWidth.m};
  background-color: ${TRACK_COLOR};
  border-radius: ${radius.circle};
  position: relative;
  margin: ${spacings.m} 0;
  position: relative;
  display: flex;
  align-items: center;
`;
const StyledTickBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${colors.muted};
  font-weight: var(--font-weight-medium);
  margin-top: calc(${spacings.s} * -1);
`;

const StyledTick = styled(Body14)``;

const Slider = ({
  min = 0,
  max = 10,
  onChange,
  onAfterChange,
  step = 1,
  value,
  persisentThumb = false,
  formatValue,
  ticks = [min, max],
  disabled,
  thumb = true,
}) => {
  let to;
  const [isActive, setIsActive] = useState(false);
  const constraintsRef = useRef(null);

  const [constraintWidth, setConstraintWidth] = useState(
    constraintsRef.current?.offsetWidth || 0
  );
  const isReady = constraintWidth > 0;
  const x = useMotionValue(0);
  const rowRealValue = useTransform(x, [0, constraintWidth], [min, max]);
  const [realValue, setRealValue] = useState(0);
  const background = useTransform(
    rowRealValue,
    [min, max],
    [
      `linear-gradient(
      90deg,
      ${TRACK_COLOR} 0%,
      ${colors.gray100} 0%
    )`,
      `linear-gradient(
      90deg,
      ${TRACK_COLOR} 100%,
      ${colors.gray100} 100%
    )`,
    ]
  );
  const debouncedRealValue = useDebounce(realValue, 200);
  const humanizeRealValue = (v) => Math.round(v / step) * step;

  const autoActive = () => {
    clearTimeout(to);
    setIsActive(true);
    to = setTimeout(() => {
      setIsActive(false);
    }, 500);
  };

  useEffect(() => {
    if (debouncedRealValue !== null && onAfterChange) {
      onAfterChange(debouncedRealValue);
    }
  }, [debouncedRealValue]);

  useEffect(() => {
    // force rerender if rowRealValue change for prevent wrong default value in Slider Knob
    setRealValue(humanizeRealValue(rowRealValue.get()));
  }, [rowRealValue.get()]);

  useEffect(() => {
    let resizeObserver;
    if (typeof ResizeObserver !== "undefined") {
      // Create a new observer instance
      resizeObserver = new ResizeObserver(() => {
        const rect = constraintsRef.current?.getBoundingClientRect();
        if (rect?.width) {
          setConstraintWidth(rect.width);
        }
      });

      if (constraintsRef.current) {
        resizeObserver.observe(constraintsRef.current);
      }
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (isReady && !isActive && value) {
      const defaultX = getRealValue(value, [min, max], [0, constraintWidth]);
      x.set(defaultX);
    }
  }, [isReady, value, min, max]);

  const handlePanEnd = (_, info) => {
    setIsActive(false);
  };

  useEffect(() => {
    if (isReady) {
      x.onChange((latest) => {
        const rv = humanizeRealValue(
          getRealValue(latest, [0, constraintWidth], [min, max])
        );
        setRealValue(rv);
        if (onChange) onChange(rv);
      });
    }
  }, [isReady]);

  const updateValues = (newX) => {
    const rect = constraintsRef.current?.getBoundingClientRect();
    const originalPos = rect.left + window.scrollX;

    // use real step value before round to nearest step in px
    const newVal = getRealValue(
      getNearestStep(
        getRealValue(newX - originalPos, [0, constraintWidth], [min, max]),
        step
      ),
      [min, max],
      [0, constraintWidth]
    );

    let res = newVal;
    const minPx = originalPos;
    const maxPx = constraintWidth + originalPos;
    if (newX < minPx) {
      res = 0;
    } else if (newX > maxPx) {
      res = constraintWidth;
    } else {
      res = newVal;
    }
    x.set(res);
  };

  const handlePan = (_, info) => {
    if (!disabled) {
      setIsActive(true);
      updateValues(info.point.x);
    }
  };

  const handleMouseDown = (e) => {
    if (!disabled) {
      setIsActive(true);
      updateValues(e.clientX);
    }
  };

  const handleMouseUp = () => {
    if (!disabled) {
      setIsActive(false);
    }
  };

  const handleKeyDown = (e) => {
    if (!disabled) {
      const pxStep = (constraintWidth * step) / max;
      const rect = constraintsRef.current?.getBoundingClientRect();
      const originalPos = rect.left + window.scrollX;
      if (e.key === "ArrowRight") {
        e.preventDefault();
        updateValues(originalPos + x.get() + pxStep);
        autoActive();
      }
      if (e.key === "ArrowLeft") {
        e.preventDefault();
        // TODO FIX : need pxStep*2 for work
        updateValues(originalPos + x.get() - pxStep * 2);
        autoActive();
      }
    }
  };

  return (
    <StyledWrapper>
      <Holder
        onPanEnd={handlePanEnd}
        onPan={handlePan}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        aria-valuemax={max}
        aria-valuemin={min}
        aria-valuenow={realValue}
        role="slider"
        disabled={disabled}
      >
        <Track ref={constraintsRef} style={{ background }}>
          <StyledAnimatedKnob style={{ x }} role="slider">
            <StyledKnob />
            {thumb && (
              <StyledTrackValue isVisible={isActive || persisentThumb}>
                <Body16 strong align="center">
                  {formatValue ? formatValue(realValue) : realValue}
                </Body16>
              </StyledTrackValue>
            )}
          </StyledAnimatedKnob>
        </Track>
      </Holder>
      {ticks && (
        <StyledTickBar>
          {ticks?.map((tick, index) => (
            <StyledTick key={`slider-tick-${index}-${tick}`}>
              {typeof tick === "function" ? tick() : tick}
            </StyledTick>
          ))}
        </StyledTickBar>
      )}
    </StyledWrapper>
  );
};

export default Slider;
