import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useFileThumbnail } from "@/modules/hooks";
import { colors, sizes, spacings } from "../assets/themes";
import Block from "./Block";
import Button from "./Button";
import Icon from "./Icon";
import { BUTTON } from "./Styles/variants";
import { Body14, Body16 } from "./Text";
import Progress from "./progress";

const INPUT_HEIGHT = 136;

const StyledFileViewerWrapper = styled.div`
  ${({ isImage, isLoading }) => css`
    background-color: ${isImage || isLoading
      ? "var(--color-gray100)"
      : "var(--color-orangered500)"};
  `}
  height: 100%;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
`;

const StyledFileWrapper = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  input:hover + label > button {
    background-color: var(--color-gray200) !important;
  }

  input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    font-size: 0;
    top: -14px;
  }
  input:hover + label {
    &:after {
      background: rgba(0, 0, 0, 0.05);
    }
  }
  input:hover:active + label {
    &:after {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    padding: var(--spacing.s);
    font-weight: 400;
    line-height: 1.5;
    color: var(--color-body);
    background-color: var(--color-gray50);
    border: 2px dashed var(--color-gray500);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    margin-bottom: 0;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transition: background-color 0.1s;
    }
  }
  ${({ dragOver }) =>
    dragOver &&
    css`
      background-color: ${colors.primary50};
      border: 2px dashed ${colors.primary500};
    `}
`;

const FilePicker = React.forwardRef(
  (
    {
      name = "image",
      onChange,
      label,
      value,
      title = "Sélectionnez un fichier",
      multiple,
      style,
      onRemove,
      className,
      progressAmount = null,
      disabled,
      ...rest
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(false);
    const fileThumbnail = useFileThumbnail();
    const [dragOver, setDragOver] = useState(false);
    const [thumbnailSrc, setThumbnailSrc] = useState(null);
    const [type, setType] = useState(null);

    const getFormatedFileName = (text) => {
      const leftRightStrings = text.split(".");
      const fName = leftRightStrings[0];
      const fExtention = leftRightStrings[1];
      return [
        fName.slice(0, fName.length - 3),
        `${fName.substr(-3)}.${fExtention}`,
      ];
    };

    const updateThumbnail = useCallback(async () => {
      setIsLoading(true);
      if (value) {
        const thumbnail = await fileThumbnail.get(value);
        setThumbnailSrc(thumbnail.src);
        setType(thumbnail.fileType);
      } else {
        setThumbnailSrc(null);
        setType(null);
      }
    }, [value, fileThumbnail]);

    useEffect(() => {
      setIsLoading(false);
    }, [type]);

    useEffect(() => {
      updateThumbnail();
    }, [value]);

    return (
      <div className={className} style={{ height: INPUT_HEIGHT, ...style }}>
        {!value && (
          <StyledFileWrapper dragOver={dragOver} disabled={disabled}>
            <input
              ref={ref}
              type="file"
              id={name}
              disabled={disabled || value || progressAmount}
              name={name}
              onChange={onChange}
              {...rest}
              onDragEnter={() => {
                setDragOver(true);
              }}
              onDragLeave={() => {
                setDragOver(false);
              }}
              onDrop={() => {
                setDragOver(false);
              }}
            />
            <label htmlFor={name}>
              {!progressAmount && (
                <>
                  <Button.Small
                    kind={BUTTON.KIND.MINIMAL}
                    shape={title ? BUTTON.SHAPE.PILL : BUTTON.SHAPE.CIRCLE}
                    css={`
                      transition: all 0.2s;
                      pointer-events: none;
                      background-color: ${dragOver
                        ? colors.primary
                        : colors.gray100}!important;
                      color: ${dragOver ? colors.onColor : colors.body};
                    `}
                  >
                    <Icon.Large name="camera-plus" />
                    {title && (
                      <Body16
                        strong
                        css={`
                          margin-left: ${spacings.s};
                        `}
                      >
                        {title}
                      </Body16>
                    )}
                  </Button.Small>
                </>
              )}
              {!!progressAmount && (
                <Progress.Circular value={progressAmount || 0} />
              )}
            </label>
          </StyledFileWrapper>
        )}
        {value && (
          <StyledFileViewerWrapper isLoading={isLoading} isImage={thumbnailSrc}>
            {!isLoading && (
              <>
                {thumbnailSrc && (
                  <img
                    className="radius-m w-100 h-100 object-fit"
                    src={thumbnailSrc}
                    alt=""
                  />
                )}
                {type === "video" && (
                  <Block
                    position="absolute"
                    bottom="0px"
                    left="0px"
                    margin={spacings.s}
                    width={sizes.size24}
                    height={sizes.size24}
                  >
                    <Icon.Large name="video" color={colors.white} />
                  </Block>
                )}
                {!thumbnailSrc && (
                  <i className="icon-file text-white icon-2x" />
                )}
                <div className="position-absolute bottom-0 left-0 right-0 p-2">
                  {!thumbnailSrc && (
                    <Block
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      textAlign="center"
                      color={colors.onColor}
                    >
                      <Body14 numberOfLines={1}>
                        {getFormatedFileName(value.name)[0]}
                      </Body14>
                      <Body14>{getFormatedFileName(value.name)[1]}</Body14>
                    </Block>
                  )}
                </div>
              </>
            )}
            <div className="position-absolute top-0 bottom-0 right-0 p-2">
              {onRemove && (
                <Button.Small
                  kind={BUTTON.KIND.MINIMAL}
                  onClick={onRemove}
                  shape={BUTTON.SHAPE.CIRCLE}
                  css={`
                    margin-left: auto;
                    background: ${colors.overlayBackdrop}!important;
                    color: ${colors.onColor}!important;
                  `}
                >
                  <i className="icon-times" />
                </Button.Small>
              )}
            </div>
          </StyledFileViewerWrapper>
        )}
      </div>
    );
  }
);

export default FilePicker;
