import polyglot from "@/utils/polyglot";

export default {
  [polyglot.t("furniture.living_room")]: [
    {
      label: polyglot.t("furniture.chair"),
      value: "chair",
      volume: 0.2,
      image: "/removal-items/chair.svg",
    },
    {
      label: polyglot.t("furniture.carpet"),
      value: "carpet",
      volume: 0.2,
      image: "/removal-items/carpet.svg",
    },
    {
      label: polyglot.t("furniture.two_seater_sofa"),
      value: "twoSeaterSofa",
      volume: 2.0,
      image: "/removal-items/twoSeaterSofa.svg",
    },
    {
      label: polyglot.t("furniture.three_seater_sofa"),
      value: "threeSeaterSofa",
      volume: 2.5,
      image: "/removal-items/threeSeaterSofa.svg",
    },
    {
      label: polyglot.t("furniture.corner_sofa"),
      value: "cornerSofa",
      volume: 3.0,
      image: "/removal-items/cornerSofa.svg",
    },
    {
      label: polyglot.t("furniture.armchair"),
      value: "armchair",
      volume: 4.0,
      image: "/removal-items/armchair.svg",
    },
    {
      label: polyglot.t("furniture.small_bookshelf"),
      value: "smallBookshelf",
      volume: 1.0,
      image: "/removal-items/smallBookshelf.svg",
    },
    {
      label: polyglot.t("furniture.large_bookshelf"),
      value: "largeBookshelf",
      volume: 2.0,
      image: "/removal-items/largeBookshelf.svg",
    },
    {
      label: polyglot.t("furniture.chandelier"),
      value: "chandelier",
      volume: 0.2,
      image: "/removal-items/chandelier.svg",
    },
    {
      label: polyglot.t("furniture.coffee_table"),
      value: "coffeeTable",
      volume: 0.5,
      image: "/removal-items/coffeeTable.svg",
    },
    {
      label: polyglot.t("furniture.living_room_table"),
      value: "livingRoomTable",
      volume: 1.5,
      image: "/removal-items/livingRoomTable.svg",
    },
    {
      label: polyglot.t("furniture.large_tv"),
      value: "largeTv",
      volume: 0.4,
      image: "/removal-items/largeTv.svg",
    },
    {
      label: polyglot.t("furniture.small_tv"),
      value: "smallTv",
      volume: 0.2,
      image: "/removal-items/smallTv.svg",
    },
    {
      label: polyglot.t("furniture.tv_stand"),
      value: "tvStand",
      volume: 0.7,
      image: "/removal-items/tvStand.svg",
    },
    {
      label: polyglot.t("furniture.small_desk"),
      value: "smallDesk",
      volume: 0.5,
      image: "/removal-items/smallDesk.svg",
    },
    {
      label: polyglot.t("furniture.large_desk"),
      value: "largeDesk",
      volume: 1.0,
      image: "/removal-items/largeDesk.svg",
    },
    {
      label: polyglot.t("furniture.large_plant"),
      value: "largePlant",
      volume: 0.3,
      image: "/removal-items/largePlant.svg",
    },
    {
      label: polyglot.t("furniture.computer"),
      value: "computer",
      volume: 0.1,
      image: "/removal-items/computer.svg",
    },
    {
      label: polyglot.t("furniture.printer"),
      value: "printer",
      volume: 0.1,
      image: "/removal-items/printer.svg",
    },
    {
      label: polyglot.t("furniture.stereo_system"),
      value: "stereoSystem",
      volume: 0.3,
      image: "/removal-items/stereoSystem.svg",
    },
    {
      label: polyglot.t("furniture.sideboard"),
      value: "sideboard",
      volume: 2.5,
      image: "/removal-items/sideboard.svg",
    },
    {
      label: polyglot.t("furniture.dresser"),
      value: "dresser",
      volume: 1.2,
      image: "/removal-items/dresser.svg",
    },
    {
      label: polyglot.t("furniture.floor_lamp"),
      value: "floorLamp",
      volume: 0.5,
      image: "/removal-items/floorLamp.svg",
    },
  ],
  [polyglot.t("furniture.kitchen")]: [
    {
      label: polyglot.t("furniture.worktop"),
      value: "worktop",
      volume: 1.0,
      image: "/removal-items/worktop.svg",
    },
    {
      label: polyglot.t("furniture.cooker"),
      value: "cooker",
      volume: 0.5,
      image: "/removal-items/cooker.svg",
    },
    {
      label: polyglot.t("furniture.cooking_plate"),
      value: "cookingPlate",
      volume: 0.1,
      image: "/removal-items/cookingPlate.svg",
    },
    {
      label: polyglot.t("furniture.refrigerator"),
      value: "refrigerator",
      volume: 1.0,
      image: "/removal-items/refrigerator.svg",
    },
    {
      label: polyglot.t("furniture.american_fridge"),
      value: "americanFridge",
      volume: 1.9,
      image: "/removal-items/americanFridge.svg",
    },
    {
      label: polyglot.t("furniture.freezer"),
      value: "freezer",
      volume: 1.5,
      image: "/removal-items/freezer.svg",
    },
    {
      label: polyglot.t("furniture.oven"),
      value: "oven",
      volume: 0.6,
      image: "/removal-items/oven.svg",
    },
    {
      label: polyglot.t("furniture.microwave"),
      value: "microwave",
      volume: 0.2,
      image: "/removal-items/microwave.svg",
    },
    {
      label: polyglot.t("furniture.dishwasher"),
      value: "dishwasher",
      volume: 0.5,
      image: "/removal-items/dishwasher.svg",
    },
    {
      label: polyglot.t("furniture.pots_and_pans"),
      value: "potsAndPans",
      volume: 0.2,
      image: "/removal-items/potsAndPans.svg",
    },
    {
      label: polyglot.t("furniture.dish_set"),
      value: "dishSet",
      volume: 1.0,
      image: "/removal-items/dishSet.svg",
    },
    {
      label: polyglot.t("furniture.coffee_maker"),
      value: "coffeeMaker",
      volume: 0.1,
      image: "/removal-items/coffeeMaker.svg",
    },
    {
      label: polyglot.t("furniture.small_appliances"),
      value: "smallAppliances",
      volume: 0.3,
      image: "/removal-items/smallAppliances.svg",
    },
    {
      label: polyglot.t("furniture.kitchen_table"),
      value: "kitchenTable",
      volume: 1.2,
      image: "/removal-items/kitchenTable.svg",
    },
    {
      label: polyglot.t("furniture.chair"),
      value: "chair",
      volume: 0.2,
      image: "/removal-items/chair.svg",
    },
    {
      label: polyglot.t("furniture.lower_kitchen_cabinet"),
      value: "lowKitchenCabinet",
      volume: 1.0,
      image: "/removal-items/lowKitchenCabinet.svg",
    },
    {
      label: polyglot.t("furniture.high_kitchen_cabinet"),
      value: "highKitchenCabinet",
      volume: 0,
      image: "/removal-items/highKitchenCabinet.svg",
    },
    {
      label: polyglot.t("furniture.wine_cellar"),
      value: "wineCellar",
      volume: 0.5,
      image: "/removal-items/wineCellar.svg",
    },
  ],
  [polyglot.t("furniture.bathroom")]: [
    {
      label: polyglot.t("furniture.washing_machine"),
      value: "washingMachine",
      volume: 0.6,
      image: "/removal-items/washingMachine.svg",
    },
    {
      label: polyglot.t("furniture.dryer"),
      value: "dryer",
      volume: 0.6,
      image: "/removal-items/dryer.svg",
    },
    {
      label: polyglot.t("furniture.two_doors_wardrobe"),
      value: "twoDoorWardrobe",
      volume: 1.2,
      image: "/removal-items/twoDoorWardrobe.svg",
    },
    {
      label: polyglot.t("furniture.one_door_wardrobe"),
      value: "oneDoorWardrobe",
      volume: 0.6,
      image: "/removal-items/oneDoorWardrobe.svg",
    },
    {
      label: polyglot.t("furniture.medicine_cabinet"),
      value: "medicineCabinet",
      volume: 0.1,
      image: "/removal-items/medicineCabinet.svg",
    },
    {
      label: polyglot.t("furniture.mirror"),
      value: "mirror",
      volume: 0.4,
      image: "/removal-items/mirror.svg",
    },
    {
      label: polyglot.t("furniture.various_item"),
      value: "variousItems",
      volume: 0.4,
      image: "/removal-items/variousItems.svg",
    },
  ],
  [polyglot.t("furniture.bedroom")]: [
    {
      label: polyglot.t("furniture.single_bed"),
      value: "singleBed",
      volume: 1.2,
      image: "/removal-items/singleBed.svg",
    },
    {
      label: "Berceau",
      value: "crib",
      volume: 0.5,
      image: "/removal-items/crib.svg",
    },
    {
      label: polyglot.t("furniture.single_matress"),
      value: "singleMattress",
      volume: 0.4,
      image: "/removal-items/singleMattress.svg",
    },
    {
      label: polyglot.t("furniture.two_doors_wardrobe"),
      value: "twoDoorWardrobe",
      volume: 1.2,
      image: "/removal-items/twoDoorWardrobe.svg",
    },
    {
      label: polyglot.t("furniture.one_door_wardrobe"),
      value: "oneDoorWardrobe",
      volume: 0.6,
      image: "/removal-items/oneDoorWardrobe.svg",
    },
    {
      label: polyglot.t("furniture.double_bed"),
      value: "doubleBed",
      volume: 3.0,
      image: "/removal-items/doubleBed.svg",
    },
    {
      label: polyglot.t("furniture.double_matress"),
      value: "doubleMattress",
      volume: 0.6,
      image: "/removal-items/doubleMattress.svg",
    },
    {
      label: polyglot.t("furniture.bunk_bed"),
      value: "bunkBed",
      volume: 2.5,
      image: "/removal-items/bunkBed.svg",
    },
    {
      label: polyglot.t("furniture.small_desk"),
      value: "smallDesk",
      volume: 0.5,
      image: "/removal-items/smallDesk.svg",
    },
    {
      label: polyglot.t("furniture.large_desk"),
      value: "largeDesk",
      volume: 1.5,
      image: "/removal-items/largeDesk.svg",
    },
    {
      label: polyglot.t("furniture.wall_shelf"),
      value: "wallShelf",
      volume: 0.06,
      image: "/removal-items/wallShelf.svg",
    },
    {
      label: polyglot.t("furniture.dressing"),
      value: "dressing",
      volume: 2.5,
      image: "/removal-items/dressing.svg",
    },
    {
      label: polyglot.t("furniture.nightstand"),
      value: "nightstand",
      volume: 0.5,
      image: "/removal-items/nightstand.svg",
    },
    {
      label: polyglot.t("furniture.dresser"),
      value: "dresser",
      volume: 1.2,
      image: "/removal-items/dresser.svg",
    },
    {
      label: polyglot.t("furniture.chair"),
      value: "chair",
      volume: 0.2,
      image: "/removal-items/chair.svg",
    },
    {
      label: polyglot.t("furniture.carpet"),
      value: "carpet",
      volume: 0.2,
      image: "/removal-items/carpet.svg",
    },
    {
      label: polyglot.t("furniture.toy_chest"),
      value: "toyChest",
      volume: 0.2,
      image: "/removal-items/toyChest.svg",
    },
  ],
  [polyglot.t("furniture.garden")]: [
    {
      label: polyglot.t("furniture.garden_table_for_eight"),
      value: "gardenTableForEight",
      volume: 2.0,
      image: "/removal-items/gardenTableForEight.svg",
    },
    {
      label: polyglot.t("furniture.lounge_chair"),
      value: "loungeChair",
      volume: 0.4,
      image: "/removal-items/loungeChair.svg",
    },
    {
      label: polyglot.t("furniture.garden_chair"),
      value: "gardenChair",
      volume: 0.3,
      image: "/removal-items/gardenChair.svg",
    },
    {
      label: polyglot.t("furniture.barbecue"),
      value: "barbecue",
      volume: 0.4,
      image: "/removal-items/barbecue.svg",
    },
    {
      label: polyglot.t("furniture.lawn_mower"),
      value: "lawnMower",
      volume: 0.4,
      image: "/removal-items/lawnMower.svg",
    },
    {
      label: polyglot.t("furniture.wheelbarrow"),
      value: "wheelbarrow",
      volume: 0.4,
      image: "/removal-items/wheelbarrow.svg",
    },
    {
      label: polyglot.t("furniture.various_tools"),
      value: "variousTools",
      volume: 0.4,
      image: "/removal-items/variousTools.svg",
    },
    {
      label: polyglot.t("furniture.parasol"),
      value: "parasol",
      volume: 0.2,
      image: "/removal-items/parasol.svg",
    },
  ],
  [polyglot.t("furniture.garage")]: [
    {
      label: polyglot.t("furniture.toolbox"),
      value: "toolbox",
      volume: 0.1,
      image: "/removal-items/toolbox.svg",
    },
    {
      label: "Vélo",
      value: "bicycle",
      volume: 0.7,
      image: "/removal-items/bicycle.svg",
    },
    {
      label: polyglot.t("furniture.scooter"),
      value: "scooter",
      volume: 1.5,
      image: "/removal-items/scooter.svg",
    },
    {
      label: polyglot.t("furniture.sports_equipment"),
      value: "sportsEquipment",
      volume: 0.5,
      image: "/removal-items/sportsEquipment.svg",
    },
  ],
  [polyglot.t("furniture.misc")]: [
    {
      label: polyglot.t("furniture.standard_box"),
      value: "standardBox",
      volume: 0.1,
      image: "/removal-items/standardBox.svg",
    },
    {
      label: polyglot.t("furniture.book_box"),
      value: "bookBox",
      volume: 0.05,
      image: "/removal-items/bookBox.svg",
    },
    {
      label: polyglot.t("furniture.wardrobe_box"),
      value: "wardrobeBox",
      volume: 0.4,
      image: "/removal-items/wardrobeBox.svg",
    },
    {
      label: polyglot.t("furniture.bicycle"),
      value: "bicycle",
      volume: 0.7,
      image: "/removal-items/bicycle.svg",
    },
    {
      label: polyglot.t("furniture.vaccum"),
      value: "vacuum",
      volume: 0.2,
      image: "/removal-items/vacuum.svg",
    },
    {
      label: polyglot.t("furniture.ironing_board"),
      value: "ironingBoard",
      volume: 0.2,
      image: "/removal-items/ironingBoard.svg",
    },
    {
      label: polyglot.t("furniture.upright_piano"),
      value: "uprightPiano",
      volume: 2.0,
      image: "/removal-items/uprightPiano.svg",
    },
    {
      label: polyglot.t("furniture.frames_or_painting"),
      value: "framesOrPainting",
      volume: 0.1,
      image: "/removal-items/framesOrPainting.svg",
    },
  ],
};
