import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { colors, spacings } from "../../../assets/themes";
import { Block, Body16, Button, Divider, Icon } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import { useAdminPreview, useToast } from "../../../modules/hooks";
import { formatPhone } from "../../../utils";
import ActionItem from "../ActionItem";
import {
  addFromParam,
  getMangopayUrlById,
  getQueryKey,
  CUSTOMER,
} from "../config";
import InformationItem from "../InformationItem";
// eslint-disable-next-line import/no-cycle
import PreviewElemCustomerJob from "../PreviewElemCustomerJob";
import polyglot from "@/utils/polyglot";

const useCustomerConfig = ({ id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { data, dataUpdateCount } = queryClient.getQueryState([
    getQueryKey({ kind: CUSTOMER, id }),
  ]);
  const adminPreview = useAdminPreview();

  const handleCopy = (phrase, value) => {
    navigator.clipboard.writeText(value);
    toast.remove();
    toast.success(phrase);
  };

  const informations = useCallback(
    () =>
      data && (
        <>
          {[
            {
              title: polyglot.t("common.id"),
              icon: "copy",
              onClick: () => {
                handleCopy(polyglot.t("clipboard_copy.id"), data.offseted_id);
              },
              RightComponent: () => <Body16>{data.offseted_id}</Body16>,
            },
            {
              title: polyglot.t("common.email"),
              icon: "copy",
              onClick: () => {
                handleCopy(polyglot.t("clipboard_copy.email"), data.email);
              },
              RightComponent: () => <Body16>{data.email}</Body16>,
            },
            {
              title: polyglot.t("common.phone_number"),
              href: `tel:${data.phone}`,
              AfterComponent: () => (
                <Block>
                  <Block marginRight={6}>
                    <Button.Small
                      kind={BUTTON.KIND.MINIMAL}
                      shape={BUTTON.SHAPE.CIRCLE}
                      onClick={() =>
                        handleCopy(
                          polyglot.t("clipboard_copy.phone"),
                          data.phone
                        )
                      }
                    >
                      <Icon.Medium name="copy" color={colors.primary} />
                    </Button.Small>
                  </Block>
                  <Divider.Cell />
                </Block>
              ),
              RightComponent: () => (
                <Block
                  marginRight={`calc(${spacings.m} * -1)`}
                  display="flex"
                  alignItems="center"
                >
                  <Block marginRight={spacings.xs}>
                    <Icon.Medium name="phone" color={colors.primary} />
                  </Block>
                  <Body16 color={colors.primary} strong>
                    {formatPhone(data.phone, { international: true })}
                  </Body16>
                </Block>
              ),
            },
            {
              title: "Quota d'adresses dépassé",
              RightComponent: () => <Body16>{data.address_quota_exceeded ? "Oui" : "Non"}</Body16>,
            },
          ].map((config, index) => (
            <InformationItem
              key={`preview-option-jobber-${id}-${index}`}
              {...config}
            />
          ))}
        </>
      ),
    [dataUpdateCount]
  );

  const tabs = [
    {
      title: "Liens utiles",
    },
    {
      title: "Actions",
    },
    {
      title: `Jobs ouverts (${data?.active_jobs.length})`,
      disabled: data?.active_jobs?.length === 0,
    },
  ];

  const menu = useCallback(
    () =>
      data && [
        [
          [
            {
              title: `Voir les notes admin (${data.admin_notes_count})`,
              onClick: () =>
                adminPreview.notes.show({ user: data, id: data?.id }),
              icon: "exclamation-circle",
            },
            {
              title: `Voir les transactions posteur`,
              href: `/kiss/users/${data.offseted_id}/show_poster_transactions`,
              icon: "double-arrow",
            },
            {
              title: "Voir le profil Mangopay",
              href: getMangopayUrlById(data.mangopay_id),
              target: "_blank",
              icon: "wallet",
            },
            {
              title: "Voir les tickets BO",
              href: `/kiss/search/tickets?search%5Buser_id_offseted%5D=${data.offseted_id}`,
              icon: "tag",
            },
            {
              title: "Voir les tickets Zendesk",
              href: `https://youpijob.zendesk.com/agent/search/1?q=${data.email}`,
              target: "_blank",
              icon: "question-circle",
            },
          ].map((config, index) => (
            <ActionItem
              key={`preview-option-customer-${data.id}-${index}`}
              {...config}
            />
          )),
        ],
        [
          [
            {
              title: "Modifier le profil",
              href: addFromParam(`/kiss/users/${data.offseted_id}/edit`),
              icon: "user-edit",
            },
            ...(data.avatar
              ? {
                  title: "Modifier la photo",
                  href: addFromParam(`/kiss/avatars/${data.offseted_id}/edit`),
                  icon: "camera",
                }
              : []),
            {
              title: "Modifier le mot de passe",
              href: addFromParam(
                `/kiss/users/${data.offseted_id}/edit_password`
              ),
              icon: "lock",
            },
          ].map((config, index) => (
            <ActionItem
              key={`preview-option-customer-${data.id}-${index}`}
              {...config}
            />
          )),
        ],
        [
          data.active_jobs.map((job, index) => (
            <div key={`active-job-${job.id}-${index}`}>
              <PreviewElemCustomerJob
                cover={job.cover}
                title={job.title}
                id={job.id}
                datetime={job.datetime}
                url={job.url}
              />
            </div>
          )),
        ],
      ],
    [dataUpdateCount]
  );

  return { informations, menu, tabs };
};

export default useCustomerConfig;
